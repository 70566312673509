<template>
  <div class="Shoppings">
    <swiper :banners="banners"></swiper>
    <div class="Shoppings-Index">
      <div v-if="clientWidth > 750" class="crumb">
        <breadcrumb :refer="refer"></breadcrumb>
      </div>
      <div class="shop-Info">
        <img src="@/assets/PC/shop/shop-Info.png" class="shop-Info-bg">
        <img src="@/assets/PC/shop/shop-Info1.png" class="shop-Info-bg1">
        <div class="shop-infos flex3">
          <div class="shop-info0 flex2">
            <img v-if="Infos.pictureUrl" :src="Infos.pictureUrl" class="Info-img">
            <img v-else src="@/assets/PC/shop/shop-InfoHead.jpg" class="Info-img">
            <div v-if="Infos.id">
              <h1 class="co-fff">{{Infos.name}}</h1>
              <p class="Info-badge">
                <img :src="Infos.level.web_bck" class="web-bck">
                <img :src="Infos.level.app_bck" class="app-bck">
                <span else>{{ Infos.level.levelName }}</span>
              </p>
            </div>
            <div v-else>
              <h1>
                <router-link :to="'/Login?redirectUrl='+this.$route.fullPath" class="cur co-fff">去登录</router-link>
              </h1>
            </div>
            <img v-if="Infos.vip" src="../../assets/PC/profile/viphead.png" alt="" class="viphead">
          </div>
          <div class="shop-info1">
            <div v-if="Infos.id">
              <p v-if="clientWidth > 750" class="flex2 pc">
                <span>可兑换金币：</span>
                <b style="position: relative">{{ Infos.money }} <i class="shadows">{{ Infos.money }}</i></b> 
                <img src="@/assets/PC/shop/money.png" alt="">
                <a class="flex0" :href="GoGoldDetail" target="_blank"><van-icon name="question-o" class="ml co-fff cur" title="查看金币获取详情"/></a>
              </p>
              <p v-if="clientWidth <= 750" class="flex2 mb">
                <b class="hidden">{{ Infos.money }}</b> <img src="@/assets/PC/shop/money.png" alt=""> <a class="flex0" :href="GoGoldDetail" target="_blank"> <van-icon name="question-o" class="ml co-fff cur" style="font-size: 16px" title="去查看金币获取详情"/></a></p>
              <p v-if="clientWidth <= 750" class="mb" style="justify-content: center;">可兑换金币</p>
            </div>
          </div>
        </div>
      </div>
      <router-view  @change="titles"/>
    </div>
  </div>
</template>

<script>
import { GetBanner } from '@/api/Home.js';
import swiper from "@/components/Swiper.vue";
import { dataState } from "@/api/all.js";
import breadcrumb from "@/components/Breadcrumb/index.vue";
import { ApiToken } from '@/utils/ApiToken'

export default {
  name: "ShoppingMall",
  data() {
    return {
      refer: [
        { name: "首页", href: "/" },
        { name: "剑盟商城" },
      ],
      loading: true,                          // !loading
      banners: [],                            // !封面图
      Infos: {
        pictureUrl:'',
        level: {
          web_bck: '',
          app_bck: '',
        }
      },
      navTitle: '',                           // !导航
      GoGoldDetail: ApiToken.shareUrl + "goldDetail",// !去金币获取途径详情页
    }
  },
  mounted(){
    if(JSON.stringify(this.$store.state.Infos) != "{}") {
      this.Infos = this.$store.state.Infos;
    }

    // !获取banner图
    GetBanner().then(res=>{
      const windowWidth = document.documentElement.clientWidth;
      if(windowWidth > 750){
        this.banners = res.data.mall;
      }else{
        this.banners = res.data.mall_m;
      }
    });

     // 数据统计
    const data ={
      "visitType": 1,
      "gid": sessionStorage.getItem('gid') ? sessionStorage.getItem('gid') : '',
      "url": window.location.href,
      "pageUrl": "ShoppingMall",
      "relationId": ""
    }
    dataState(data).then(res => {
      sessionStorage.setItem('gid',res.data);
    })
  },
  methods:{
    // !用户信息共享
    getInfos(){
      if(this.$store.state.Infos){
        this.Infos = this.$store.state.Infos;
      }
    },
    // !详情页传来的
    titles(val){
      if(this.refer.length == 2) {
        this.navTitle = val
        this.refer.pop();
        this.refer.push({'name': '剑盟商城', href: "/Home/ShoppingMall/ShopList" });
        
      }else if(this.refer.length == 3){

        this.navTitle = val
      }
      
      this.refer.push({'name': this.navTitle});
      this.navTitle = '';
    },
  },
  computed: {
    clientWidth () {
      return this.$store.state.clientWidth;
    },
  },
  watch:{
    // !监听用户信息
    "$store.state.Infos": "getInfos",
    $route: {
      handler(val) {
        if(val.fullPath == "/Home/ShoppingMall/ShopList"){
          if(this.refer.length > 2) {
            this.refer.pop();
          }
        }
      },
      deep: true,
    }
  },
  components: {
    swiper,
    breadcrumb
  },
}
</script>

<style lang="scss" scoped>
  $mobil_base: 750;

  @function vw($true) {
    @return ($true / $mobil_base) * 100vw;
  }

  .Shoppings{
    .Shoppings-Index {width: 1200px;margin: 0 auto;margin-bottom: 150px;}
    .shop-Info {width: 100%;position: relative;box-shadow: 0 0 10px #ccc;margin-bottom: 10px;}
    .shop-Info .shop-Info-bg,.shop-Info .shop-Info-bg1{width: 100%;}
    .shop-Info .shop-Info-bg1 {display: none;}
    .shop-infos {width: 100%;height: 152px;position: absolute;top: 0;left: 0;padding: 0 32px;}

    .shop-info0 .Info-img {width: 104px;height: 104px;border-radius: 50%;margin-right: 15px;}
    .shop-info0 h1 {font-size: 18px;margin-bottom: 10px;}
    .shop-info0 h1 a {font-size: 18px;}
    .Info-badge {position: relative;}
    .app-bck,.shop-info1 .mb {display: none;}
    .shadows {position: absolute; left: 0; top: 32px; font-size: 32px; transform: rotateX(180deg); background: linear-gradient(to bottom, #481066, #481066, rgb(151, 151, 151));  -webkit-background-clip: text; color: transparent; opacity: 0.3;}
    .shadows::selection { background:none; }
    .Info-badge span {position: absolute;left: 40px; color: #fff;top: 8px;line-height: 1}

    .shop-info1 p{font-size: 16px;color: #D0B4DA;}
    .shop-info1 b {font-size: 32px; color: #fff;margin-left: 5px; line-height: 1}
    .shop-info1 p img {margin-left: 10px;width: 15px;height: 15px;}

    .van-icon-question-o {font-size: 15px;}

    .viphead {position: absolute; left: 19px; top: 18px; width: 125px;}
  }

@media (max-width: 750px) {
  .Shoppings{
    .crumb {display: none;}
    .Shoppings-Index {width: 100%;margin: 0;}
    .shop-infos {height: vw(120);margin-top: vw(39);padding: vw(39);}
    .shop-info0 {width: vw(500);}
    .shop-info0 .Info-img {width: vw(120);height: vw(120);}
    .web-bck, .shop-info1 .pc {display: none;}
    .app-bck {display: block;height: vw(48);}
    .shop-info1 .mb {display: flex;}
    .shop-info0 h1 {white-space: nowrap;width: vw(350);overflow: hidden;text-overflow: ellipsis;}
    .shop-info0 h1, .shop-info0 h1 a {font-size: vw(28);}
    .Info-badge span {left: vw(50); color: #fff;top: vw(12);font-size: vw(24);}

    .shop-Info .shop-Info-bg {display: none;}
    .shop-Info .shop-Info-bg1 {display: block;}

    .shop-info1 p b {font-size: vw(32);}
    .shop-info1 p img {margin-left: vw(12);width: vw(26);height: vw(26);}
    .shop-Info p:last-of-type {font-size: vw(24);margin-top: 5px;}

    .viphead {top: vw(-10); width: vw(150); left: vw(20);}
  }
}
</style>